import React, { useEffect, useState } from "react";

import BouncerLogo from "../../assets/images/bouncer-logo2.svg";
// import createAccount from "../../assets/documentationAssets/create-account.png";
import shopifyHome from "../../assets/documentationAssets/shopify-home.webp";
import installShopifyApp from "../../assets/documentationAssets/install-shopify-app.webp";
import apiKey from "../../assets/documentationAssets/api-key.webp";
import shopifySetup from "../../assets/documentationAssets/shopify-setup.png";
import shopifySetupSmall from "../../assets/documentationAssets/shopify-setup-small.png";
import verifyEmail from "../../assets/documentationAssets/verify-email.png";
import createOrganizationPage from "../../assets/documentationAssets/create-organization-page.png";
import secureKey from "../../assets/documentationAssets/secure-key.png";
import verificationDiagram from "../../assets/documentationAssets/verification-diagram.png";
import backButtonLogo from "../../assets/images/back-button.svg";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { Trans, useTranslation } from "react-i18next";

import "./ShopifyIntegration.css";

const PORTAL_URL = "https://portal.bouncer.global";
const MARKETPLACE_URL = "https://apps.shopify.com/bouncer-age-verification";

const ShopifyIntegration = () => {
  const { t } = useTranslation();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const urlString =
    "https://age.bouncer.global/?session_token={{session_token}}&redirect_url=https://your.website/verifications";
  const urlStringTwo =
    "https://your.website/verifications?results_token={{results_token}}";

  return (
    <div className="ShopifyIntegration">
      <div className="ShopifyIntegration__container">
        <div className="Banner">
          <img
            onClick={() => {
              window.location.href = "https://www.bouncer.global/";
            }}
            className="BackButton"
            src={backButtonLogo}
          />
          <LanguageSelect />
        </div>
        <div className="Header">
          <div style={{ fontSize: "25px" }}>{t("shopifyInt.mainTitle")}</div>
          <img
            style={{ width: "250px", cursor: "pointer" }}
            src={BouncerLogo}
          />
        </div>

        <div className="Subheader">
          <div style={{ fontSize: "25px", marginBottom: "30px" }}>
            {t("shopifyInt.title")}
          </div>
          <div
            className="SubheaderParagraph"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            {t("shopifyInt.subtitle")}
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step1")}:
            </div>
            <div className="StepFirstLayout-title">
                <Trans components={{ marketplace: <a target="_blank" href={ MARKETPLACE_URL } /> }}>
                    {t("shopifyInt.step1Title")}
                </Trans>
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={shopifyHome} alt="" />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step2")}:
            </div>
            <div className="StepFirstLayout-title">
                {t("shopifyInt.step2Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={installShopifyApp} alt="" />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step3")}:
            </div>
            <div className="StepFirstLayout-title">
                <Trans components={{ redirect: <a target="_blank" href={ PORTAL_URL } /> }}>
                    {t("shopifyInt.step3bTitle")}
                </Trans>
            </div>
          </div>

          {/* <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={createAccount} />
          </div> */}
          <div
            data-i18n="[html]shopifyInt.step3Description"
            dangerouslySetInnerHTML={{
              __html: t("shopifyInt.step3Description", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step4")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("shopifyInt.step4Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={verifyEmail} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("shopifyInt.step5")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("shopifyInt.step5Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={createOrganizationPage}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("shopifyInt.step6")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("shopifyInt.step6Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={secureKey} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step7")}:
            </div>
            <div className="StepFirstLayout-title">
                {t("shopifyInt.step7Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={apiKey} alt="" />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("shopifyInt.step8")}:
            </div>
            <div className="StepFirstLayout-title">
                {t("shopifyInt.step8Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img onClick={() => setShowImage(true)} className="StepFirstLayout-content-img zoom" src={shopifySetupSmall} alt="" />
            {showImage && <div onClick={() => setShowImage(false)} className="floatingContent"
            >
                <img src={shopifySetup} alt="" />
            </div>
            }
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-title">
              {t("shopifyInt.step9")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={verificationDiagram}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyIntegration;
